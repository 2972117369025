/* eslint-disable */
// @ts-nocheck

/* eslint-enable prettier/prettier */
/* eslint-enable @typescript-eslint/no-unused-vars */
/* eslint-enable import/no-unresolved */

import React from "react";
import PropTypes from "prop-types";

import WidgetCard from "@untyped-shared/materialUI/widgetCard";

import TextOptionsCard from "./TextOptionsCard";
import SurveyChartCard from "./SurveyChartCard";

import { useSelector } from "react-redux";

import { selectOptionsFromQuestion } from "@untyped-store/slices/survey/surveysQuestionsSlice";

function SurveyResultsQuestionRow({ surveyQuestion }) {
  const options = useSelector((state) =>
    selectOptionsFromQuestion(state, surveyQuestion.id),
  );

  let row;

  if (options.length < 1) {
    row = I18n.t("js-error-not-logged");
  } else if (options[0].is_text) {
    row = <TextOptionsCard option={options[0]} />;
  } else {
    row = <SurveyChartCard options={options} />;
  }

  return (
    <WidgetCard
      head={surveyQuestion.text}
      body={row}
      smallMargin={!!window.legacyTemplate}
    />
  );
}

SurveyResultsQuestionRow.propTypes = {
  surveyQuestion: PropTypes.shape({
    id: PropTypes.number.isRequired,
    text: PropTypes.string.isRequired,
  }),
};

export default SurveyResultsQuestionRow;
