/* eslint-disable */
// @ts-nocheck

/* eslint-enable prettier/prettier */
/* eslint-enable @typescript-eslint/no-unused-vars */
/* eslint-enable import/no-unresolved */

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import DataService from "@untyped-services/dataService";

const dataService = new DataService();

export const getStatistics = createAsyncThunk(
  "statistics/getStatistics",
  async (url) => {
    const response = await dataService.getData(url);
    return response.data;
  },
);

export const statisticsSlice = createSlice({
  name: "statistics",
  initialState: {
    devicesData: [],
    languagesData: [],
    loading: false,
    error: "",
  },
  reducers: {},
  extraReducers: {
    [getStatistics.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [getStatistics.fulfilled]: (state, action) => {
      state.loading = false;
      state.attendeeCountries = action.payload.attendee_countries;
      state.devicesData =
        action.payload.device_type.length !== undefined &&
        action.payload.device_type.map((device) => {
          return {
            name: device.label,
            value: device.data,
          };
        });
      state.languagesData = Object.keys(action.payload.attendee_locales).map(
        (locale) => {
          return {
            name: I18n.t(`languages.${locale}`),
            value: action.payload.attendee_locales[locale],
          };
        },
      );
    },
    [getStatistics.rejected]: (state, action) => {
      state.error = action.error.message;
      state.loading = false;
    },
  },
});

export const selectDevicesData = (state) => {
  return state.statistics.devicesData;
};

export const selectLanguagesData = (state) => {
  return state.statistics.languagesData;
};

export const selectAttendeeCountries = (state) => {
  return state.statistics.attendeeCountries;
};

export default statisticsSlice.reducer;
