import React from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import {
  EventInfo,
  ClassicEditor,
  AccessibilityHelp,
  Alignment,
  AutoImage,
  AutoLink,
  Autosave,
  BalloonToolbar,
  Base64UploadAdapter,
  Bold,
  Essentials,
  FontBackgroundColor,
  FontColor,
  FontFamily,
  FontSize,
  GeneralHtmlSupport,
  Heading,
  ImageBlock,
  ImageCaption,
  ImageInline,
  ImageInsert,
  ImageInsertViaUrl,
  ImageResize,
  ImageStyle,
  ImageTextAlternative,
  ImageToolbar,
  Indent,
  IndentBlock,
  Italic,
  Link,
  LinkImage,
  List,
  ListProperties,
  MediaEmbed,
  Paragraph,
  RemoveFormat,
  SelectAll,
  ShowBlocks,
  SourceEditing,
  SpecialCharacters,
  SpecialCharactersArrows,
  SpecialCharactersCurrency,
  SpecialCharactersEssentials,
  SpecialCharactersLatin,
  SpecialCharactersMathematical,
  SpecialCharactersText,
  Strikethrough,
  Subscript,
  Superscript,
  Table,
  TableCaption,
  TableColumnResize,
  TableToolbar,
  Underline,
  Undo,
  EditorConfig,
} from "ckeditor5";
import useHideInTestEnv from "@untyped-hooks/useHideInTestEnv";
import "ckeditor5/ckeditor5.css";
import "../editor.css";

const items = [
  "undo",
  "redo",
  "|",
  "fontSize",
  "fontFamily",
  "fontColor",
  "fontBackgroundColor",
  "|",
  "bold",
  "italic",
  "underline",
  "strikethrough",
  "removeFormat",
  "|",
  "heading",
  "link",
  "insertImage",
  "mediaEmbed",
  "insertTable",
  "|",
  "alignment",
  "|",
  "bulletedList",
  "numberedList",
  "outdent",
  "indent",
];
const plugins = [
  AccessibilityHelp,
  Alignment,
  AutoImage,
  AutoLink,
  Autosave,
  BalloonToolbar,
  Base64UploadAdapter,
  Bold,
  Essentials,
  FontBackgroundColor,
  FontColor,
  FontFamily,
  FontSize,
  GeneralHtmlSupport,
  Heading,
  ImageBlock,
  ImageCaption,
  ImageInline,
  ImageInsert,
  ImageInsertViaUrl,
  ImageResize,
  ImageStyle,
  ImageTextAlternative,
  ImageToolbar,
  Indent,
  IndentBlock,
  Italic,
  Link,
  LinkImage,
  List,
  ListProperties,
  MediaEmbed,
  Paragraph,
  RemoveFormat,
  SelectAll,
  ShowBlocks,
  SourceEditing,
  SpecialCharacters,
  SpecialCharactersArrows,
  SpecialCharactersCurrency,
  SpecialCharactersEssentials,
  SpecialCharactersLatin,
  SpecialCharactersMathematical,
  SpecialCharactersText,
  Strikethrough,
  Subscript,
  Superscript,
  Table,
  TableCaption,
  TableColumnResize,
  TableToolbar,
  Underline,
  Undo,
];
const blockToolbar = [
  "fontSize",
  "fontColor",
  "fontBackgroundColor",
  "|",
  "outdent",
  "indent",
];
const headings = [
  {
    model: "paragraph",
    title: "Paragraph",
    class: "ck-heading_paragraph",
  },
  {
    model: "heading1",
    view: "h1",
    title: "Heading 1",
    class: "ck-heading_heading1",
  },
  {
    model: "heading2",
    view: "h2",
    title: "Heading 2",
    class: "ck-heading_heading2",
  },
  {
    model: "heading3",
    view: "h3",
    title: "Heading 3",
    class: "ck-heading_heading3",
  },
  {
    model: "heading4",
    view: "h4",
    title: "Heading 4",
    class: "ck-heading_heading4",
  },
  {
    model: "heading5",
    view: "h5",
    title: "Heading 5",
    class: "ck-heading_heading5",
  },
  {
    model: "heading6",
    view: "h6",
    title: "Heading 6",
    class: "ck-heading_heading6",
  },
];

type TProps = {
  onChange: (eventInfo: EventInfo, classicEditor: ClassicEditor) => void;
  isSuperAdmin: boolean;
  event: {
    description: string;
  };
};

export default function CkEditor({ isSuperAdmin, event, onChange }: TProps) {
  const config: EditorConfig = {
    toolbar: {
      items,
      shouldNotGroupWhenFull: true,
    },
    // @ts-expect-error wrong assertion
    ui: { poweredBy: { side: "left" } },
    plugins,
    blockToolbar,
    balloonToolbar: ["bold", "italic", "underline", "|", "link", "insertImage"],
    fontFamily: { supportAllValues: true },
    initialData: event.description === "" ? undefined : event.description,
    // @ts-expect-error wrong assertion
    heading: { options: headings },
    htmlSupport: {
      allow: [
        {
          name: /^.*$/,
          styles: true,
          attributes: true,
          classes: true,
        },
      ],
    },
    image: {
      toolbar: [
        "toggleImageCaption",
        "imageTextAlternative",
        "|",
        "imageStyle:inline",
        "imageStyle:wrapText",
        "imageStyle:breakText",
        "|",
        "resizeImage",
      ],
    },
    menuBar: {
      isVisible: true,
      removeItems: !isSuperAdmin ? ["view"] : [],
    },
    table: {
      contentToolbar: ["tableColumn", "tableRow", "mergeTableCells"],
    },
  };
  const { hideInTest } = useHideInTestEnv();
  return hideInTest(
    <CKEditor
      editor={ClassicEditor}
      config={{ ...config }}
      onChange={onChange}
    />,
  );
}
