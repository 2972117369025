/* eslint-disable */
// @ts-nocheck

/* eslint-enable prettier/prettier */
/* eslint-enable @typescript-eslint/no-unused-vars */
/* eslint-enable import/no-unresolved */

import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
  spanFadeOut: ({ time }) => ({
    opacity: 0,
    transition: `all 250ms linear ${time}ms`,
  }),
  spanFadeIn: {
    opacity: 1,
    transition: "all 250ms linear",
  },
}));

function FadeOutComponent({ show, children, time }) {
  const [showing, setShowing] = useState(false);
  const classes = useStyles({ time });

  useEffect(() => {
    show && setShowing(true);
  }, [show]);

  return (
    <span
      className={showing ? classes.spanFadeIn : classes.spanFadeOut}
      onTransitionEnd={() => setShowing(false)}
    >
      {children}
    </span>
  );
}

FadeOutComponent.propTypes = {
  show: PropTypes.bool,
  time: PropTypes.number,
  children: PropTypes.node,
};

export default FadeOutComponent;
