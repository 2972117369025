/* eslint-disable */
// @ts-nocheck

/* eslint-enable prettier/prettier */
/* eslint-enable @typescript-eslint/no-unused-vars */
/* eslint-enable import/no-unresolved */

import React from "react";
import PropTypes from "prop-types";

function SurveyAnonymouseNote({ note }) {
  return (
    <p id="anonymous_warning">
      <em>{note}</em>
    </p>
  );
}

SurveyAnonymouseNote.propTypes = {
  note: PropTypes.string,
};

export default SurveyAnonymouseNote;
