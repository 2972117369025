/* eslint-disable */
// @ts-nocheck

/* eslint-enable prettier/prettier */
/* eslint-enable @typescript-eslint/no-unused-vars */
/* eslint-enable import/no-unresolved */

import React from "react";
import {
  SpinnerWrapper,
  SpinnerContent,
  SpinnerOutsideBorder,
  SpinnerInsideBorder,
} from "./StyledSpinner";

function Spinner() {
  return (
    <SpinnerWrapper>
      <SpinnerContent>
        <SpinnerOutsideBorder />
        <SpinnerInsideBorder />
      </SpinnerContent>
    </SpinnerWrapper>
  );
}

export default Spinner;
