/* eslint-disable */
// @ts-nocheck

/* eslint-enable prettier/prettier */
/* eslint-enable @typescript-eslint/no-unused-vars */
/* eslint-enable import/no-unresolved */

import React from "react";
import PropTypes from "prop-types";
import { PieChart, Pie, Cell, Sector, ResponsiveContainer } from "recharts";

const renderActiveShape = (props) => {
  const { cx, cy, innerRadius, outerRadius, startAngle, endAngle, payload } =
    props;

  return (
    <g>
      <text
        x={cx}
        y={cy}
        dy={0}
        textAnchor="middle"
        fill="#838383"
        style={{ fontSize: innerRadius / 3 }}
      >
        {payload.name.slice(0, 10)}
      </text>
      <text
        x={cx}
        y={cy}
        dy={innerRadius / 3}
        textAnchor="middle"
        fill="#838383"
        style={{ fontSize: innerRadius / 4 }}
      >
        {`${Math.round(payload.percent)} %`}
      </text>
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius - 1}
        outerRadius={outerRadius + 1}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={payload.color}
      />
      <Sector
        cx={cx}
        cy={cy}
        startAngle={startAngle}
        endAngle={endAngle}
        innerRadius={outerRadius * 1.06}
        outerRadius={outerRadius * 1.14}
        fill={payload.color}
      />
    </g>
  );
};

function PieChartGraph({ data, activeIndex, setActiveIndex, height }) {
  return (
    <ResponsiveContainer height={height} width="100%">
      <PieChart>
        <Pie
          activeIndex={activeIndex}
          activeShape={renderActiveShape}
          data={data}
          innerRadius={height / 2 - height / 4}
          outerRadius={height / 2 - height / 10}
          dataKey="value"
          onMouseEnter={(_, index) => {
            setActiveIndex(index);
          }}
          onMouseLeave={() => {
            setActiveIndex(-1);
          }}
        >
          {data.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={entry.color} />
          ))}
        </Pie>
      </PieChart>
    </ResponsiveContainer>
  );
}

PieChartGraph.propTypes = {
  data: PropTypes.array,
  activeIndex: PropTypes.number,
  setActiveIndex: PropTypes.func,
  onPieEnter: PropTypes.func,
  height: PropTypes.number,
};

export default PieChartGraph;
