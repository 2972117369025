/* eslint-disable */
// @ts-nocheck

/* eslint-enable prettier/prettier */
/* eslint-enable @typescript-eslint/no-unused-vars */
/* eslint-enable import/no-unresolved */

import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import QuestionVoteitem from "../questionVoteItem";

function QuestionItem({ question, questionVoting, callback }) {
  const { author, message, message_edit, vote_count, voted, isAnswered } =
    question;
  const [vote, setVote] = useState(voted);
  const [disable, setDisable] = useState(false);
  const questionOwner = question.authorOwner || false;
  let timeout;

  useEffect(() => {
    setVote(voted);
    if (questionOwner) {
      setDisable(true);
    }
  }, [voted, questionOwner]);

  // cleanup timeout if component got unmounted during update
  useEffect(() => {
    return () => clearTimeout(timeout);
  }, []);

  const voteHandler = () => {
    setDisable(true);
    setVote((prev) => !prev);
    callback();
    timeout = setTimeout(() => {
      setDisable(false);
    }, 2000);
  };

  return (
    <li className="list-group-item">
      {questionVoting && (
        <QuestionVoteitem
          voteCount={vote_count}
          isVoted={vote}
          authorOwner={questionOwner}
          answered={isAnswered}
          disabled={disable}
          callVoted={voteHandler}
        />
      )}
      <div className="clear">
        <strong> {author || "Anonymous"} </strong>
      </div>
      <p
        className="m-1"
        dangerouslySetInnerHTML={{ __html: message_edit || message }}
      />
    </li>
  );
}

export default QuestionItem;

QuestionItem.propTypes = {
  question: PropTypes.object,
  voted: PropTypes.bool,
  callback: PropTypes.func,
  author: PropTypes.string,
  message: PropTypes.string,
  vote_count: PropTypes.number,
  isAnswered: PropTypes.bool,
  questionVoting: PropTypes.bool,
};
