/* eslint-disable */
// @ts-nocheck

/* eslint-enable prettier/prettier */
/* eslint-enable @typescript-eslint/no-unused-vars */
/* eslint-enable import/no-unresolved */

import React from "react";
import PropTypes from "prop-types";

import { Field } from "formik";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
  boxText: {
    fontSize: "12px",
    padding: "5px 10px",
    resize: "vertical",
    maxHeight: "344px",
  },
}));

function SurveyTextareaInput({ question }) {
  const option = question.options[0];
  const classes = useStyles();
  return (
    <Field
      component="textarea"
      rows="3"
      className={`form-control ${classes.boxText}`}
      name={question.id}
      placeholder={option.text}
    />
  );
}

SurveyTextareaInput.propTypes = {
  question: PropTypes.shape({
    options: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        text: PropTypes.string,
      }),
    ),
    id: PropTypes.number,
  }),
};

export default SurveyTextareaInput;
