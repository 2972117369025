/* eslint-disable */
// @ts-nocheck

/* eslint-enable prettier/prettier */
/* eslint-enable @typescript-eslint/no-unused-vars */
/* eslint-enable import/no-unresolved */

import React from "react";
import { SpinnerCompContainer, SpinnerCompContent } from "./StyledSpinnerComp";

function SpinnerComp() {
  return (
    <SpinnerCompContainer>
      <SpinnerCompContent />
    </SpinnerCompContainer>
  );
}

export default SpinnerComp;
