/* eslint-disable */
// @ts-nocheck

/* eslint-enable prettier/prettier */
/* eslint-enable @typescript-eslint/no-unused-vars */
/* eslint-enable import/no-unresolved */

import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core";

import { getValidAnswers } from "./getValidAnswers";
import SurveyCardFooter from "./SurveyCardFooter";

export const useStyles = makeStyles({
  answersBody: {
    padding: "10px 0px",
    fontFamily: "Open Sans",
    fontSize: "14px",
    wordBreak: "break-all",
  },
});

function TextOptionsBody({ answers }) {
  const classes = useStyles();
  const validAnswers = getValidAnswers(answers);

  const total = answers.length;

  return (
    <>
      {validAnswers.map((ans) => {
        return (
          <h6 key={ans.id} className={classes.answersBody}>
            {ans.input_value}
          </h6>
        );
      })}
      <SurveyCardFooter total={total} />
    </>
  );
}

TextOptionsBody.propTypes = {
  answers: PropTypes.array,
};

export default TextOptionsBody;
