/* eslint-disable */
// @ts-nocheck

/* eslint-enable prettier/prettier */
/* eslint-enable @typescript-eslint/no-unused-vars */
/* eslint-enable import/no-unresolved */

import {
  createEntityAdapter,
  createAsyncThunk,
  createSlice,
  createSelector,
} from "@reduxjs/toolkit";
import DataService from "@untyped-services/dataService";

const dataService = new DataService();

export const getSurvey = createAsyncThunk(
  "surveys/getSurvey",
  async ({ surveyId }, thunkAPI) => {
    const url = `/api/surveys/${surveyId}`;
    const jwt = thunkAPI.getState().jwt.token;

    return dataService.getData(url, jwt).then((res) => res.data);
  },
);

// implementation based on
// https://redux-toolkit.js.org/api/createEntityAdapter
const surveysAdapter = createEntityAdapter();

// selectors from entityAdapter
const surveysSelectors = surveysAdapter.getSelectors((state) => state.surveys);

const surveysSlice = createSlice({
  name: "surveys",
  initialState: surveysAdapter.getInitialState({
    loading: false,
    error: "",
    activeSurvey: undefined,
  }),
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getSurvey.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getSurvey.fulfilled, (state, action) => {
      surveysAdapter.upsertOne(state, action.payload);
      state.activeSurvey = action.payload.id;
      state.loading = false;
      state.error = "";
    });
    builder.addCase(getSurvey.rejected, (state, action) => {
      surveysAdapter.updateOne(state, action.payload);
      state.loading = false;
      state.error = action.error.message;
    });
  },
});

/// / exports
// active survey
export const selectActiveSurvey = (state) =>
  surveysSelectors.selectById(state, state.surveys.activeSurvey);

export const selectActiveSurveyName = createSelector(
  selectActiveSurvey,
  (survey) => survey && survey.name,
);

export const selectSurveyLoading = (state) => {
  return state.surveys.loading;
};

// reducers
export default surveysSlice.reducer;
