/* eslint-disable */
// @ts-nocheck

/* eslint-enable prettier/prettier */
/* eslint-enable @typescript-eslint/no-unused-vars */
/* eslint-enable import/no-unresolved */

import React from "react";
import PropTypes from "prop-types";

function SurveyDescription({ text }) {
  return <div className="m-b survey_description">{text}</div>;
}

SurveyDescription.propTypes = {
  text: PropTypes.string,
};

export default SurveyDescription;
