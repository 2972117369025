/* eslint-disable */
// @ts-nocheck

/* eslint-enable prettier/prettier */
/* eslint-enable @typescript-eslint/no-unused-vars */
/* eslint-enable import/no-unresolved */

import {
  createEntityAdapter,
  createAsyncThunk,
  createSlice,
  createSelector,
} from "@reduxjs/toolkit";
import DataService from "@untyped-services/dataService";

const dataService = new DataService();

export const getSurveyQuestions = createAsyncThunk(
  "surveys/getSurveyQuestions",
  async ({ surveyId }, thunkAPI) => {
    const url = `/api/surveys/${surveyId}/questions`;
    const jwt = thunkAPI.getState().jwt.token;

    return dataService.getData(url, jwt).then((res) => res.data);
  },
);

const surveyQuestionsAdapter = createEntityAdapter();

const surveyQuestionsSelectors = surveyQuestionsAdapter.getSelectors(
  (state) => state.surveyQuestions,
);

const surveysQuestionsSlice = createSlice({
  name: "surveyQuestions",
  initialState: surveyQuestionsAdapter.getInitialState({
    error: "",
  }),
  reducers: {
    updateSurveyQuestion(state, action) {
      surveyQuestionsAdapter.updateOne(state, action.payload);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getSurveyQuestions.fulfilled, (state, action) => {
      surveyQuestionsAdapter.upsertMany(state, action.payload.questions);
      state.error = "";
    });
    builder.addCase(getSurveyQuestions.rejected, (state, action) => {
      state.error = action.error.message;
    });
  },
});

export const selectQuestionsForActiveSurvey = createSelector(
  [
    (state) => state.surveys.activeSurvey,
    (state) => surveyQuestionsSelectors.selectAll(state),
  ],
  (activeSurvey, questions) =>
    questions.filter((question) => question.survey_id === activeSurvey),
);

export const selectOptionsFromQuestion = (state, questionId) =>
  surveyQuestionsSelectors.selectById(state, questionId).options;

export const { updateSurveyQuestion } = surveysQuestionsSlice.actions;

export default surveysQuestionsSlice.reducer;
