/* eslint-disable */
// @ts-nocheck

/* eslint-enable prettier/prettier */
/* eslint-enable @typescript-eslint/no-unused-vars */
/* eslint-enable import/no-unresolved */

import React from "react";
import PropTypes from "prop-types";

import store from "@untyped-store";
import { Provider } from "react-redux";
import { ThemeProvider } from "@mui/material";
import ScopedCssBaseline from "@mui/material/ScopedCssBaseline";
import theme from "@untyped-styles/theme";

function RootComponent({ children, providedStore }) {
  return (
    <ThemeProvider theme={theme}>
      <ScopedCssBaseline>
        <Provider store={providedStore || store}>{children}</Provider>
      </ScopedCssBaseline>
    </ThemeProvider>
  );
}

RootComponent.propTypes = {
  children: PropTypes.node,
  providedStore: PropTypes.object,
};

export default RootComponent;
