/* eslint-disable */
// @ts-nocheck

/* eslint-enable prettier/prettier */
/* eslint-enable @typescript-eslint/no-unused-vars */
/* eslint-enable import/no-unresolved */

import {
  createEntityAdapter,
  createAsyncThunk,
  createSlice,
} from "@reduxjs/toolkit";
import DataService from "@untyped-services/dataService";

const dataService = new DataService();

export const getStream = createAsyncThunk(
  "streams/getStream",
  async ({ streamId }, thunkAPI) => {
    const url = `/api/streams/${streamId}`;
    const jwt = thunkAPI.getState().jwt.token;

    return dataService.getData(url, jwt).then((res) => res.data);
  },
);

export const getStreamsOfEvent = createAsyncThunk(
  "streams/getStreamsOfEvent",
  async (eventId) => {
    const url = `/api/events/${eventId}/streams.json`;
    const response = await dataService.getData(url);
    return response.data;
  },
);

// implementation based on
// https://redux-toolkit.js.org/api/createEntityAdapter
const streamsAdapter = createEntityAdapter();

// selectors from entityAdapter
const streamsSelectors = streamsAdapter.getSelectors((state) => state.streams);

const streamsSlice = createSlice({
  name: "streams",
  initialState: streamsAdapter.getInitialState({
    loading: false,
    error: "",
    activeStream: undefined,
  }),
  reducers: {
    addStream: streamsAdapter.upsertOne,
  },
  extraReducers: (builder) => {
    builder.addCase(getStream.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getStream.fulfilled, (state, action) => {
      streamsAdapter.upsertOne(state, action.payload);
      state.activeStream = action.payload.id;
      state.loading = false;
      state.error = "";
    });
    builder.addCase(getStream.rejected, (state, action) => {
      streamsAdapter.updateOne(state, action.payload);
      state.loading = false;
      state.error = action.error.message;
    });
    builder.addCase(getStreamsOfEvent.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getStreamsOfEvent.fulfilled, (state, action) => {
      streamsAdapter.setAll(state, action.payload.streams);
      state.loading = false;
      state.error = "";
    });
    builder.addCase(getStreamsOfEvent.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });
  },
});

/// / exports
// active stream
export const selectActiveStream = (state) =>
  streamsSelectors.selectById(state, state.streams.activeStream);

export const selectStreamLoading = (state) => {
  return state.streams.loading;
};

// actions
export const { addStream, loadAndSelectNewStream } = streamsSlice.actions;

export const {
  selectById: selectStreamById,
  selectIds: selectStreamIds,
  selectEntities: selectStreamEntities,
  selectAll: selectAllStreams,
  selectTotal: selectTotalStreams,
} = streamsAdapter.getSelectors((state) => state.streams);

// reducers
export default streamsSlice.reducer;
