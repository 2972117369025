/* eslint-disable */
// @ts-nocheck

/* eslint-enable prettier/prettier */
/* eslint-enable @typescript-eslint/no-unused-vars */
/* eslint-enable import/no-unresolved */

import React, { useEffect, useState } from "react";
import {
  FirstColumn,
  SecondColumn,
  RowStyle,
} from "./SurveyChartCardStyles/SurveyChartCardStyles";
import PropTypes from "prop-types";

import { HI_CONSTRAST_COLOR } from "@untyped-shared/colors";

import { useSelector, useDispatch } from "react-redux";

import useInterval from "@untyped-components/hooks/useInterval";

import PieChartGraph from "@untyped-shared/charts/pieChart/pieChart";
import SurveyChartData from "./SurveyChartData";
import SurveyCardFooter from "../SurveyCardFooter";
import {
  getSurveyOptionAnswers,
  selectAllAnswersFromOptions,
} from "@untyped-store/slices/survey/surveysAnswersSlice";

function SurveyChartCard({ options }) {
  const dispatch = useDispatch();
  const [activeIndex, setActiveIndex] = useState(-1);
  const optionAnswers = useSelector((state) =>
    selectAllAnswersFromOptions(state, options),
  );

  const total = optionAnswers.reduce(
    (acc, answers) => acc + answers?.length,
    0,
  );

  useEffect(() => {
    options.forEach((option) => {
      dispatch(getSurveyOptionAnswers({ optionId: option.id }));
    });
  }, []);

  useInterval(() => {
    options.forEach((option) => {
      dispatch(getSurveyOptionAnswers({ optionId: option.id }));
    });
  }, 15000);

  const data = options.map((option, index) => {
    const obj = { ...option };
    obj.name = option.text;
    obj.percent = total == 0 ? 0 : (optionAnswers[index]?.length / total) * 100;
    obj.value = optionAnswers[index]?.length;
    obj.color = HI_CONSTRAST_COLOR[index % HI_CONSTRAST_COLOR.length];
    return obj;
  });

  return (
    <RowStyle>
      <FirstColumn>
        <div className="survey-text-container">
          <SurveyChartData
            data={data}
            setActiveIndex={setActiveIndex}
            activeIndex={activeIndex}
          />
          <SurveyCardFooter total={total} />
        </div>
      </FirstColumn>
      <SecondColumn>
        <PieChartGraph
          data={data}
          activeIndex={activeIndex}
          setActiveIndex={setActiveIndex}
          height={200}
        />
      </SecondColumn>
    </RowStyle>
  );
}

SurveyChartCard.propTypes = {
  options: PropTypes.array,
};

export default SurveyChartCard;
