/* eslint-disable */
// @ts-nocheck

/* eslint-enable prettier/prettier */
/* eslint-enable @typescript-eslint/no-unused-vars */
/* eslint-enable import/no-unresolved */

/* global $ */
/* global appConfig */

export default class QuestionService {
  event = appConfig.event || {};

  customizations = appConfig.customizations;

  messages = appConfig.messages;

  attendee = appConfig.attendee || {};

  getConfigForm = () => {
    return {
      event_check: this.event && this.event.encoded_id,
      defaultName: !this.attendee ? "" : this.attendee.stringify,
      alertSuccessMessage: this.messages.questions.alert_success_message,
      alertErrorMessage: this.messages.questions.alert_error_message,
      authorLabel: this.messages.questions.author_label,
      textLabel: this.messages.questions.text_label,
      textPlaceholder: this.messages.questions.text_placeholder,
      attendeeId: !this.attendee ? false : this.attendee.id,
      maxLengthQuestion: this.customizations.question_max_length || 500,
    };
  };

  getConfigList = () => {
    return {
      eventId: this.event.encoded_id,
      attendeeId: this.attendee.id,
      questionVoting: this.questionVoting,
    };
  };

  setDataVote = async (item) => {
    await $.post(item.vote_url, { vote: !item.voted }, "json");
  };
}
