/* eslint-disable */
// @ts-nocheck

/* eslint-enable prettier/prettier */
/* eslint-enable @typescript-eslint/no-unused-vars */
/* eslint-enable import/no-unresolved */

import { createTheme, responsiveFontSizes } from "@mui/material";

let theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
  typography: {
    fontFamily: [
      '"Open Sans"',
      '"Helvetica Neue"',
      '"Helvetica"',
      '"Arial"',
      '"sans-serif"',
    ].join(","),
    panelHeader: {
      color: "#717171",
      fontSize: "13px",
    },
    callToAction: {
      fontSize: "1.2rem",
    },
    h5: {
      fontWeight: 400,
      fontSize: "1.3rem",
    },
    h3: {
      fontSize: "2.8rem",
    },
    body2: {
      fontSize: "1.2rem",
    },
    hint: {
      fontSize: "12px",
      color: "#979797",
      lineHeight: "normal",
    },
    errorHint: {
      fontWeight: "600",
    },
    error: {
      color: "#FB6B5B",
      fontSize: "12px",
      lineHeight: "normal",
    },
  },
  palette: {
    background: {
      default: "transparent",
    },
    primary: {
      main: "#594f8d",
    },
    secondary: {
      main: "#4c5566",
    },
    success: {
      main: "#92CF5C",
    },
    info: {
      main: "#5DCFF3",
    },
    warning: {
      main: "#FCC44D",
    },
    error: {
      main: "#FB6B5B",
    },
    shadowBackground: {
      main: "#F9F9F9",
    },
    cancelBackground: {
      main: "#f3f5f9",
    },
    greyIcons: {
      main: "#717171",
    },
  },
  components: {
    MuiTypography: {
      defaultProps: {
        fontFamily: [
          '"Open Sans"',
          '"Helvetica Neue"',
          '"Helvetica"',
          "Arial",
          "sans-serif",
        ].join(","),
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          fontSize: "1rem",
        },
      },
    },
  },
  // This should be removed after updating conference to Mui v5 SSY-2075
  overrides: {
    MuiCssBaseline: {
      "@global": {
        "@font-size": 10,
        "@color": "rgb(113, 113, 113)",
      },
    },
    MuiSwitch: {
      colorSecondary: {
        "&$checked": {
          // Controls checked color for the thumb
          color: "#92CF5C",
        },
      },
      track: {
        // Controls default (unchecked) color for the track
        backgroundColor: "#999",

        "$checked$checked + &": {
          // Controls checked color for the track
          opacity: 0.7,
          backgroundColor: "#92CF5C",
        },
      },
    },
  },
});

theme = responsiveFontSizes(theme);

export default theme;
