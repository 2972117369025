/* eslint-disable */
// @ts-nocheck

/* eslint-enable prettier/prettier */
/* eslint-enable @typescript-eslint/no-unused-vars */
/* eslint-enable import/no-unresolved */

// Took from grafana repo:
// https://github.com/grafana/grafana/blob/main/packages/grafana-ui/src/utils/colors.ts
export const getColorsForQuestionState = (questionState) => {
  switch (questionState) {
    case 0:
      // incoming
      return {
        backgroundColor: "#5c677c",
        color: "#c4c9d3",
      };
    case 200:
      // live
      return {
        backgroundColor: "#81daf6",
        color: "#ffffff",
      };
    case 300:
      // answered
      return {
        backgroundColor: "#92cf5c",
        color: "#fbfdfa",
      };
    case 400:
      // deleted
      return {
        backgroundColor: "#979797",
        color: "#fff",
      };
    default:
  }
};

export const HI_CONSTRAST_COLOR = [
  "#A6D87A", // 0: pale green
  "#FDD073", // 1: orange
  "#81DAF6", // 2: light blue
  "#FC8D80", // 3: light red
  "#9DA8FF", // 4: lilac
  "#4867E6", // 5: violet
  "#B75DA3", // 6: purple
  "#FCB880", // 7: pale orange
  "#59AF86", // 8: dark green
  "#CCA300", // 9: dark sand
  "#447EBC",
  "#C15C17",
  "#890F02",
  "#0A437C",
  "#6D1F62",
  "#584477",
  "#B7DBAB",
  "#F4D598",
  "#70DBED",
  "#F9BA8F",
  "#F29191",
  "#82B5D8",
  "#E5A8E2",
  "#AEA2E0",
  "#629E51",
  "#E5AC0E",
  "#64B0C8",
  "#E0752D",
  "#BF1B00",
  "#0A50A1",
  "#962D82",
  "#614D93",
  "#9AC48A",
  "#F2C96D",
  "#65C5DB",
  "#F9934E",
  "#EA6460",
  "#5195CE",
  "#D683CE",
  "#806EB7",
  "#3F6833",
  "#967302",
  "#2F575E",
  "#99440A",
  "#58140C",
  "#052B51",
  "#511749",
  "#3F2B5B",
  "#E0F9D7",
  "#FCEACA",
  "#CFFAFF",
  "#F9E2D2",
  "#FCE2DE",
  "#BADFF4",
  "#F9D9F9",
  "#DEDAF7",
];
