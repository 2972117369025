/* eslint-disable */
// @ts-nocheck

/* eslint-enable prettier/prettier */
/* eslint-enable @typescript-eslint/no-unused-vars */
/* eslint-enable import/no-unresolved */

import React, { useState, useEffect, forwardRef } from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Button } from "@material-ui/core";
import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab";

import AccessTimeIcon from "@material-ui/icons/AccessTime";
import ThumbUpIcon from "@material-ui/icons/ThumbUp";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLongArrowAltUp } from "@fortawesome/free-solid-svg-icons";
import Tippy from "@tippyjs/react";

import { selectPublicQuestionsLiveOnly } from "@untyped-store/selectors/activeEvent";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: `0 0 ${theme.spacing(1)}px 0`,
    color: "#717171",
  },
  toggleIcons: {
    paddingRight: 2,
  },
  toggleButtons: {
    padding: 4,
    textAlign: "start",
    marginBlock: "10px",
  },
  endButton: {
    padding: 4,
    textAlign: "end",
    marginBlock: "10px",
  },
  btn: {
    textTransform: "none",
    fontSize: "1.2rem",
    maxHeight: 31,
    "&:focus": {
      outline: "none",
      border: "1px solid rgba(0, 0, 0, 0.15)",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.1rem",
    },
  },
  activeBtn: {
    color: "rgba(0, 0, 0, 0.54)",
    backgroundColor: "rgba(0, 0, 0, 0.12)",
    border: "1px solid rgba(0, 0, 0, 0.15)",
    padding: "7px 12px",
    "&:hover": {
      color: "rgba(0, 0, 0, 0.54)",
      backgroundColor: "rgba(0, 0, 0, 0.15)",
      borderColor: "rgba(0, 0, 0, 0.15)",
    },
  },
  inactiveBtn: {
    color: "rgba(0, 0, 0, 0.38)",
    backgroundColor: "transparent",
    border: "1px solid rgba(0, 0, 0, 0.15)",
    padding: "7px 12px",
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.05)",
      borderColor: "#e2e6ea",
    },
  },
}));
let active = false;

const TooltipToggleButton = forwardRef((props, ref) => {
  const { TooltipProps, ...rest } = props;

  return (
    <Tippy {...TooltipProps}>
      <ToggleButton ref={ref} {...rest} />
    </Tippy>
  );
});

TooltipToggleButton.displayName = "TooltipToggleButton";

function ManagerQaList({ sortByVotes, hideAnswered }) {
  const classes = useStyles();
  const [alignment, setAlignment] = useState("time");
  const [disable, setDisable] = useState(false);
  const publicQuestionsLiveOnly = useSelector(selectPublicQuestionsLiveOnly);

  useEffect(() => {
    const timer = setTimeout(() => {
      setDisable(false);
    }, 2000);
    return () => clearTimeout(timer);
  }, [disable]);

  const handleAlignment = (event, newAlignment) => {
    if (newAlignment !== null) {
      setAlignment(newAlignment);
      const value = newAlignment === "vote";
      sortByVotes(value);
    }
  };

  const handleHideClick = () => {
    if (!disable) {
      setDisable(true);
      active = !active;
      hideAnswered(active);
    }
  };

  return (
    <Grid
      container
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      className={classes.root}
    >
      <Grid item xs={4} className={classes.toggleButtons}>
        <ToggleButtonGroup
          value={alignment}
          exclusive
          onChange={handleAlignment}
          aria-label="text alignment"
          size="small"
        >
          <TooltipToggleButton
            value="time"
            aria-label="left aligned"
            disableRipple
            className="questions-time-sort"
            TooltipProps={{
              content: I18n.t("questions.sort_by_time"),
            }}
          >
            <AccessTimeIcon className={classes.toggleIcons} />
            <FontAwesomeIcon icon={faLongArrowAltUp} style={{ fontSize: 12 }} />
          </TooltipToggleButton>
          <TooltipToggleButton
            value="vote"
            aria-label="right aligned"
            disableRipple
            className="questions-vote-sort"
            TooltipProps={{
              content: I18n.t("questions.sort_by_votes"),
            }}
          >
            <ThumbUpIcon className={classes.toggleIcons} />
            <FontAwesomeIcon icon={faLongArrowAltUp} style={{ fontSize: 12 }} />
          </TooltipToggleButton>
        </ToggleButtonGroup>
      </Grid>
      {!publicQuestionsLiveOnly && (
        <Grid item xs={8} className={classes.endButton}>
          <Button
            variant="outlined"
            className={`${`${classes.btn} answered-questions`}
          ${active ? classes.activeBtn : classes.inactiveBtn}`}
            startIcon={active ? <VisibilityOffIcon /> : <VisibilityIcon />}
            onClick={handleHideClick}
            disabled={disable}
            disableRipple
          >
            {I18n.t("questions.hide_answered")}
          </Button>
        </Grid>
      )}
    </Grid>
  );
}

ManagerQaList.propTypes = {
  sortByVotes: PropTypes.func,
  hideAnswered: PropTypes.func,
};

TooltipToggleButton.propTypes = {
  TooltipProps: PropTypes.object,
};

export default ManagerQaList;
