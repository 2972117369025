/* eslint-disable */
// @ts-nocheck

/* eslint-enable prettier/prettier */
/* eslint-enable @typescript-eslint/no-unused-vars */
/* eslint-enable import/no-unresolved */

import React, { useEffect } from "react";
import PropTypes from "prop-types";

import SurveyForm from "./SurveyForm";
import SurveyResults from "./SurveyResults";
import Spinner from "@untyped-components/Spinner/Spinner";
import Grid from "@mui/material/Grid";

import { useSelector, useDispatch } from "react-redux";
import useInterval from "@untyped-components/hooks/useInterval";

import {
  getSurvey,
  selectActiveSurvey,
  selectSurveyLoading,
} from "@untyped-store/slices/surveysSlice";

import { selectShowPubQA } from "@untyped-store/selectors/activeEvent";

import { selectBackendView } from "@untyped-store/slices/metaSlice";

function EventSurvey({ forId }) {
  const dispatch = useDispatch();
  const loading = useSelector(selectSurveyLoading);
  const survey = useSelector(selectActiveSurvey);
  const isBackend = useSelector(selectBackendView);
  const pubQAEnabled = useSelector(selectShowPubQA);
  const maxWidth = pubQAEnabled ? "2665px" : "1330px";

  let view;
  if (survey) {
    switch (true) {
      case isBackend:
        view = <SurveyResults />;
        break;
      case survey.publicResults && !survey.canParticipate:
        view = <SurveyResults />;
        break;
      default:
        view = <SurveyForm />;
    }
  }

  useEffect(() => {
    dispatch(getSurvey({ surveyId: forId }));
  }, []);

  useInterval(() => {
    dispatch(getSurvey({ surveyId: forId }));
  }, 60000);

  // TODO: SSY-2044 set width by a shared root component
  const style = { minHeight: "95%" };
  if (!isBackend) style.maxWidth = maxWidth;

  return (
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="stretch"
      style={style}
    >
      {loading && !survey && <Spinner />}
      {view}
    </Grid>
  );
}

EventSurvey.propTypes = {
  forId: PropTypes.string,
};

export default EventSurvey;
