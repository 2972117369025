/* eslint-disable */
// @ts-nocheck

/* eslint-enable prettier/prettier */
/* eslint-enable @typescript-eslint/no-unused-vars */
/* eslint-enable import/no-unresolved */

import React, { useState } from "react";
import { useSelector } from "react-redux";
import ReactHtmlParser from "react-html-parser";
import PropTypes from "prop-types";
import { selectActiveEvent } from "@untyped-store/slices/eventsSlice";
import { debounce } from "throttle-debounce";
import CKEditor from "@untyped-shared/eventFeatures/CkEditor/component";

import DataService from "@untyped-services/dataService";
import FloatingTagHolder from "@untyped-shared/tags/FloatingTagHolder";
import FadeOutComponent from "@untyped-shared/FadeOutComponent";
import SavedTag from "@untyped-shared/tags/SavedTag";
import ErrorTag from "@untyped-shared/tags/ErrorTag";

const dataService = new DataService();

const EventDescription = ({ editable, isSuperAdmin }) => {
  const event = useSelector(selectActiveEvent);
  const [saving, setSaving] = useState(false);
  const [touched, setTouched] = useState(false);
  const [error, setError] = useState(false);

  const sendData = debounce(1000, (event, data) => {
    // setTouched(true) is needed to only show the saving tag when the user has
    // actually changed the description. Otherwise, the saving tag will show
    // when the page is loaded.
    setTouched(true);
    setSaving(true);
    const url = `/api/events/${event.id}/update_description`;
    if (data.description === event.description) return;
    dataService
      .sendData(url, data, "put")
      .then(() => {
        setSaving(false);
      })
      .catch(() => {
        setSaving(false);
        setError(true);
      });
  });

  if (editable) {
    return (
      <div className="description-editor">
        <CKEditor
          isSuperAdmin={isSuperAdmin}
          event={event}
          onChange={(_, editor) => {
            const data = { data: editor.getData(), locale: I18n.locale };
            sendData(event, data);
          }}
        />
        <FloatingTagHolder>
          <FadeOutComponent show={touched && !saving} time={700}>
            {error ? <ErrorTag /> : <SavedTag />}
          </FadeOutComponent>
        </FloatingTagHolder>
      </div>
    );
  } else {
    return ReactHtmlParser(event.description);
  }
};

EventDescription.propTypes = {
  editable: PropTypes.bool,
  isSuperAdmin: PropTypes.bool,
};

export default EventDescription;
