/* eslint-disable */
// @ts-nocheck

/* eslint-enable prettier/prettier */
/* eslint-enable @typescript-eslint/no-unused-vars */
/* eslint-enable import/no-unresolved */

import React from "react";
import Tag from "./Tag";

function ErrorTag() {
  return <Tag color="#fb6b5b" text={I18n.t("error")} />;
}

export default ErrorTag;
