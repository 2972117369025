/* eslint-disable */
// @ts-nocheck

/* eslint-enable prettier/prettier */
/* eslint-enable @typescript-eslint/no-unused-vars */
/* eslint-enable import/no-unresolved */

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import DataService from "@untyped-services/dataService";

const dataService = new DataService();

const getAnalytics = createAsyncThunk(
  "eventAnalytics/getAnalytics",
  async (id) => {
    const response = await dataService.getData(`/api/users/${id}/analytics`);
    return response.data;
  },
);

export const eventAnalyticsSlice = createSlice({
  name: "eventAnalytics",
  initialState: { analyticsData: [], loading: false, error: "" },
  reducers: {},
  extraReducers: {
    [getAnalytics.pending]: (state) => {
      if (!state.loading && !state.analyticsData.length) {
        state.loading = true;
      }
    },
    [getAnalytics.fulfilled]: (state, action) => {
      if (state.loading) {
        state.loading = false;
        state.analyticsData = action.payload;
      }
    },
    [getAnalytics.rejected]: (state, action) => {
      if (state.loading) {
        state.loading = false;
        state.analyticsData = [];
        state.error = action.error;
      }
    },
  },
});

export const { init } = eventAnalyticsSlice.actions;

export const selectAnalyticsData = (state) => {
  return state.eventAnalytics.analyticsData;
};

export const selectLoading = (state) => {
  return state.eventAnalytics.loading;
};

export { getAnalytics };
export default eventAnalyticsSlice.reducer;
