/* eslint-disable */
// @ts-nocheck

/* eslint-enable prettier/prettier */
/* eslint-enable @typescript-eslint/no-unused-vars */
/* eslint-enable import/no-unresolved */

import { styled } from "@mui/system";

const FloatingTagHolder = styled("div")({
  position: "absolute",
  top: "0",
  right: "0",
  transform: "translateX(100%)",
  zIndex: "100",
});

export default FloatingTagHolder;
