/* eslint-disable */
// @ts-nocheck

/* eslint-enable prettier/prettier */
/* eslint-enable @typescript-eslint/no-unused-vars */
/* eslint-enable import/no-unresolved */

import { styled } from "@mui/system";

export const SpinnerWrapper = styled("div")({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
});

export const SpinnerContent = styled("div")({
  position: "relative",
  width: "77px !important",
  height: "77px !important",
  "@keyframes lds-double-ring": {
    "0%": {
      transform: "rotate(0)",
    },
    "100%": {
      transform: "rotate(360deg)",
    },
  },
  "@keyframes lds-double-ring_reverse": {
    "0%": {
      transform: "rotate(0)",
    },
    "100%": {
      transform: "rotate(-360deg)",
    },
  },
  transform:
    "translate(-38.5px, -38.5px) scale(0.385) translate(38.5px, 38.5px)",
});

export const SpinnerOutsideBorder = styled("div")({
  position: "absolute",
  width: "160px",
  height: "160px",
  top: "20px",
  left: "20px",
  borderRadius: "50%",
  border: "8px solid #000",
  borderColor: "#594f8d transparent #594f8d transparent",
  animation: "lds-double-ring 1.5s linear infinite",
});

export const SpinnerInsideBorder = styled("div")({
  position: "absolute",
  width: "140px",
  height: "140px",
  top: "30px",
  left: "30px",
  border: "8px solid #000",
  borderRadius: "50%",
  borderColor: "transparent #a7b4d3 transparent #a7b4d3",
  animation: "lds-double-ring_reverse 1.5s linear infinite",
});
