/* eslint-disable */
// @ts-nocheck

/* eslint-enable prettier/prettier */
/* eslint-enable @typescript-eslint/no-unused-vars */
/* eslint-enable import/no-unresolved */

/* global I18n */

import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import VoteTooltip from "@untyped-shared/tooltip/VoteTooltip";

function QuestionVoteitem({
  voteCount,
  isVoted,
  authorOwner,
  answered,
  disabled,
  callVoted,
}) {
  const [styles, setStyles] = useState(
    !isVoted ? "fa-thumbs-o-up" : "fa-thumbs-up",
  );

  useEffect(() => {
    if (authorOwner) {
      setStyles("fa-thumbs-up");
    } else {
      setStyles(!isVoted ? "fa-thumbs-o-up" : "fa-thumbs-up");
    }
  }, [authorOwner, isVoted]);

  const handleClick = () => {
    if (!disabled) {
      callVoted(!isVoted);
    }
  };

  const showVotes = () => {
    return (
      !answered && (
        <div
          className={
            I18n.locale !== "ar"
              ? "votes fa-hover pull-right"
              : "votes fa-hover pull-left"
          }
        >
          <span className="vote-button" onClick={handleClick}>
            {!authorOwner ? (
              <i className={`fa fa-lg m-2 ${styles}`} />
            ) : (
              <VoteTooltip content={I18n.t("questions.disabled_vote")}>
                <i className={`fa fa-lg m-2 ${styles}`} />
              </VoteTooltip>
            )}
          </span>
          <span
            className="badge"
            style={{
              backgroundColor: "#a7b4d3",
            }}
          >
            {voteCount}
          </span>
        </div>
      )
    );
  };

  const showAnswered = () => {
    return (
      answered && (
        <div className="pull-right">
          <span
            className="m-l-xs label"
            style={{
              backgroundColor: "#92cf5c",
            }}
          >
            {I18n.t("questions.answered")}
          </span>
        </div>
      )
    );
  };
  return (
    <>
      {showVotes()}
      {showAnswered()}
    </>
  );
}

QuestionVoteitem.propTypes = {
  voteCount: PropTypes.number,
  isVoted: PropTypes.bool,
  authorOwner: PropTypes.bool,
  answered: PropTypes.bool,
  disabled: PropTypes.bool,
  callVoted: PropTypes.func,
};

export default QuestionVoteitem;
