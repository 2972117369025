/* eslint-disable */
// @ts-nocheck

/* eslint-enable prettier/prettier */
/* eslint-enable @typescript-eslint/no-unused-vars */
/* eslint-enable import/no-unresolved */

import React from "react";
import PropTypes from "prop-types";

function SurveyTitle({ text }) {
  return <h2 className="h4 m-b survey_name">{text}</h2>;
}

SurveyTitle.propTypes = {
  text: PropTypes.string,
};

export default SurveyTitle;
