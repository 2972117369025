/* eslint-disable */
// @ts-nocheck

/* eslint-enable prettier/prettier */
/* eslint-enable @typescript-eslint/no-unused-vars */
/* eslint-enable import/no-unresolved */

import React from "react";
import Tag from "./Tag";

function SavedTag() {
  return <Tag color="#92cf5c" text={I18n.t("saved")} />;
}

export default SavedTag;
