/* eslint-disable */
// @ts-nocheck

/* eslint-enable prettier/prettier */
/* eslint-enable @typescript-eslint/no-unused-vars */
/* eslint-enable import/no-unresolved */

import { createSlice, createSelector } from "@reduxjs/toolkit";

import { selectActiveEvent } from "./eventsSlice";

export const metaSlice = createSlice({
  name: "viewSettings",
  initialState: {
    backendView: false,
    newBackendTool: false,
    isFocusViewTemplate: false,
  },
  reducers: {
    setIsFocusViewTemplate(state, action) {
      state.isFocusViewTemplate = action.payload;
    },
    setNewBackendTool(state, action) {
      state.newBackendTool = action.payload;
    },
    setBackendView(state, action) {
      state.backendView = action.payload;
    },
    setShowPreview(state, action) {
      state.showPreview = action.payload;
    },
  },
});

// actions
export const {
  setBackendView,
  setShowPreview,
  setNewBackendTool,
  setIsFocusViewTemplate,
} = metaSlice.actions;

// selectors
const selectSelf = (state) => state;
export const selectBackendView = createSelector(
  selectSelf,
  (state) => state.meta.backendView,
);

export const selectIsFocusViewTemplate = createSelector(
  selectSelf,
  (state) => state.meta.isFocusViewTemplate,
);

export const selectNewBackendTool = createSelector(
  selectSelf,
  (state) => state.meta.newBackendTool,
);

export const selectShowPreview = createSelector(
  [selectSelf, selectActiveEvent],
  (state, event) => {
    return state.meta.showPreview && event && event.state != "live";
  },
);

export default metaSlice.reducer;
