/* eslint-disable */
// @ts-nocheck

/* eslint-enable prettier/prettier */
/* eslint-enable @typescript-eslint/no-unused-vars */
/* eslint-enable import/no-unresolved */

import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { setJWT } from "@untyped-store/slices/jwtSlice";
import { getEvent, upsertEvent } from "@untyped-store/slices/eventsSlice";
import { subscribeToEventState } from "@untyped-store/middleware/websocketMiddleware";

const useEvent = ({ eventId, jwt, overwriteState }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    if (jwt) dispatch(setJWT(jwt));
    dispatch(getEvent({ eventId }));
    if (overwriteState) {
      dispatch(upsertEvent({ id: eventId, overwrittenState: overwriteState }));
    }
    // will return a promise from the faye subscription object
    // some people might play with the idea to make a middleware to unpromise the value
    // but that wont work, because we need the actual reference to the subscription object, not a serialized version of it!
    const subObj = dispatch(subscribeToEventState(eventId));
    return () => {
      // dont do: dispatch(unsubscribeFromEventState(eventId));
      // we need to cancel the listener on the subscription object level
      // not the entire subscription to the channel, which will happen with the above line
      // because of issue: SSY-2846
      // for more infos please look into the comments of the ticket
      subObj.then(({ subscription }) => {
        subscription.cancel();
      });
    };
  }, [eventId]);
};
export default useEvent;
