/* eslint-disable */
// @ts-nocheck

/* eslint-enable prettier/prettier */
/* eslint-enable @typescript-eslint/no-unused-vars */
/* eslint-enable import/no-unresolved */

import React from "react";
import PropTypes from "prop-types";

import FormQuestionVertical from "./formQuestionVertical";
import FormQuestionHorizontal from "./formQuestionHorizontal";

function FormQuestion({ horizontal = false }) {
  return horizontal ? <FormQuestionHorizontal /> : <FormQuestionVertical />;
}

FormQuestion.propTypes = {
  horizontal: PropTypes.bool,
};

export default FormQuestion;
