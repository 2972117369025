/* eslint-disable */
// @ts-nocheck

/* eslint-enable prettier/prettier */
/* eslint-enable @typescript-eslint/no-unused-vars */
/* eslint-enable import/no-unresolved */

import { ReactNode } from "react";

/**
 * - use this hook to wrap components that are incompatible with Jest
 * - will return a wrapper function that can be used to handle the incompatible node
 */
const useHideInTestEnv = () => {
  const runningInTestEnv: boolean | undefined = Util.inJest;

  return {
    hideInTest: (node: ReactNode) => (runningInTestEnv ? null : node),
  };
};

export default useHideInTestEnv;
