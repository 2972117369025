/* eslint-disable */
// @ts-nocheck

/* eslint-enable prettier/prettier */
/* eslint-enable @typescript-eslint/no-unused-vars */
/* eslint-enable import/no-unresolved */

import { configureStore } from "@reduxjs/toolkit";

import { socketMiddleware } from "./middleware/websocketMiddleware";

import { questionsOrderMiddleware } from "./middleware/questionsOrderMiddleware";

import reducer from "./reducer";

export default configureStore({
  reducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
      .concat(socketMiddleware)
      .concat(questionsOrderMiddleware),
});
