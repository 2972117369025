/* eslint-disable */
// @ts-nocheck

/* eslint-enable prettier/prettier */
/* eslint-enable @typescript-eslint/no-unused-vars */
/* eslint-enable import/no-unresolved */

import React, { useEffect } from "react";

import { List } from "@material-ui/core";

import {
  selectQuestionsForActiveSurvey,
  getSurveyQuestions,
} from "@untyped-store/slices/survey/surveysQuestionsSlice";

import { selectActiveSurvey } from "@untyped-store/slices/surveysSlice";

import {
  selectBackendView,
  selectIsFocusViewTemplate,
} from "@untyped-store/slices/metaSlice";

import { useSelector, useDispatch } from "react-redux";

import SurveyResultsQuestionRow from "./SurveyResultsQuestionRow";

function SurveyResults() {
  const dispatch = useDispatch();
  const survey = useSelector(selectActiveSurvey);
  const surveyQuestions = useSelector(selectQuestionsForActiveSurvey);
  const isBackend = useSelector(selectBackendView);
  const isFocusViewTemplate = useSelector(selectIsFocusViewTemplate);

  const styling = {
    overflowY: "auto",
    maxWidth: "100%",
  };

  if (!isBackend && isFocusViewTemplate) {
    styling.maxHeight = "70vh";
  }

  useEffect(() => {
    dispatch(getSurveyQuestions({ surveyId: survey.id }));
  }, [survey.id]);

  return (
    <List id="survey-results" style={styling}>
      {surveyQuestions?.map((question) => (
        <SurveyResultsQuestionRow surveyQuestion={question} key={question.id} />
      ))}
    </List>
  );
}

export default SurveyResults;
