/* eslint-disable */
// @ts-nocheck

/* eslint-enable prettier/prettier */
/* eslint-enable @typescript-eslint/no-unused-vars */
/* eslint-enable import/no-unresolved */

import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
} from "@reduxjs/toolkit";
import DataService from "@untyped-services/dataService";

const dataService = new DataService();

export const getIframesData = createAsyncThunk(
  "iframes/getIframesData",
  async ({ eventId }, thunkAPI) => {
    const url = `/api/events/${eventId}/iframes`;
    const jwt = thunkAPI.getState().jwt.token;

    const response = await dataService
      .getData(url, jwt)
      .then((res) => res.data);
    return response;
  },
);

export const createIframe = createAsyncThunk(
  "iframes/createIframe",
  async ({ eventId, iframe }) => {
    return dataService
      .sendData(
        `/api/events/${eventId}/iframes`,
        {
          iframe,
        },
        "post",
      )
      .then((res) => res.data);
  },
);

export const updateIframeData = createAsyncThunk(
  "iframes/updateIframeData",
  async ({ eventId, iframeId, changes }) => {
    const url = `/api/events/${eventId}/iframes/${iframeId}`;
    return dataService
      .putData(url, { iframe: changes })
      .then((res) => res.data);
  },
);

const iframesAdapter = createEntityAdapter({ selectId: (iframe) => iframe.id });

export const iframesSlice = createSlice({
  name: "iframes",
  initialState: iframesAdapter.getInitialState({
    loading: false,
    error: "",
  }),
  reducers: {
    updateIframe(state, action) {
      iframesAdapter.updateOne(state, action.payload);
    },
    addIframe(state, action) {
      iframesAdapter.addOne(state, action.payload);
    },
  },
  extraReducers: {
    [getIframesData.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },

    [getIframesData.fulfilled]: (state, { payload }) => {
      state.loading = false;
      iframesAdapter.setAll(state, payload);
    },

    [getIframesData.rejected]: (state) => {
      state.loading = false;
    },
    [updateIframeData.pending]: (state, action) => {
      state.loading = true;
      iframesAdapter.updateOne(state, {
        id: action.meta.arg.iframeId,
        changes: action.meta.arg.changes,
      });
    },
    [updateIframeData.fulfilled]: (state, action) => {
      iframesAdapter.updateOne(state, action.payload);
      state.loading = false;
    },
    [createIframe.fulfilled]: (state, action) => {
      iframesAdapter.addOne(state, action.payload);
      state.loading = false;
    },
  },
});

export const iframesSelectors = iframesAdapter.getSelectors(
  (state) => state.iframes,
);

export const selectIframes = (state) => {
  return iframesSelectors.selectAll(state);
};

export const { updateIframe, addIframe } = iframesSlice.actions;

export default iframesSlice.reducer;
