/* eslint-disable */
// @ts-nocheck

/* eslint-enable prettier/prettier */
/* eslint-enable @typescript-eslint/no-unused-vars */
/* eslint-enable import/no-unresolved */

import { createEntityAdapter, createSlice } from "@reduxjs/toolkit";

const uploadsAdapter = createEntityAdapter();

export const uploaderSlice = createSlice({
  name: "uploader",
  initialState: uploadsAdapter.getInitialState({}),
  reducers: {
    upsertUpload: uploadsAdapter.upsertOne,
    addUpload: uploadsAdapter.upsertOne,
    deleteAllUploads: uploadsAdapter.removeAll,
  },
});

// actions
export const { upsertUpload, addUpload, deleteAllUploads } =
  uploaderSlice.actions;

// selectors
export const { selectAll: selectAllUploads } = uploadsAdapter.getSelectors(
  (state) => state.uploader,
);

export default uploaderSlice.reducer;
