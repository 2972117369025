/* eslint-disable */
// @ts-nocheck

/* eslint-enable prettier/prettier */
/* eslint-enable @typescript-eslint/no-unused-vars */
/* eslint-enable import/no-unresolved */

import React, { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles, Tab, Tabs } from "@material-ui/core";

import WordsCloud from "./WordsCloud";
import TextOptionsBody from "./TextOptionsBody";

import { selectBackendView } from "@untyped-store/slices/metaSlice";

import useInterval from "@untyped-components/hooks/useInterval";

import {
  selectAnswersForOption,
  getSurveyOptionAnswers,
} from "@untyped-store/slices/survey/surveysAnswersSlice";

export const useStyles = makeStyles({
  tabs: {
    fontFaily: "Open Sans",
    fontSize: "13px",
  },
});

function TextOptionsCard({ option }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const answersTitle = I18n.t("surveys.answers.index");
  const wordsCloudTitle = I18n.t("surveys.wordcloud");
  const isTextFieldType = option.type === "Survey::Option::Textfield";
  const isBackend = useSelector(selectBackendView);
  const [selectedTab, setSelectedTab] = useState(0);
  const optionAnswers = useSelector((state) =>
    selectAnswersForOption(state, option.id),
  );

  const handleChange = useCallback((_event, newTabValue) => {
    setSelectedTab(newTabValue);
  }, []);

  useEffect(() => {
    dispatch(getSurveyOptionAnswers({ optionId: option.id }));
  }, []);

  useInterval(() => {
    dispatch(getSurveyOptionAnswers({ optionId: option.id }));
  }, 5000);

  if (!isTextFieldType) {
    return <TextOptionsBody answers={optionAnswers} />;
  }
  if (!isBackend) {
    return <WordsCloud answers={optionAnswers} />;
  }
  return (
    <>
      <Tabs
        value={selectedTab}
        indicatorColor="primary"
        onChange={handleChange}
      >
        <Tab className={classes.tabs} label={answersTitle} />
        <Tab className={classes.tabs} label={wordsCloudTitle} />
      </Tabs>
      {selectedTab === 0 && <TextOptionsBody answers={optionAnswers} />}
      {selectedTab === 1 && <WordsCloud answers={optionAnswers} />}
    </>
  );
}

TextOptionsCard.propTypes = {
  option: PropTypes.shape({
    id: PropTypes.number.isRequired,
    type: PropTypes.string.isRequired,
  }),
};

export default TextOptionsCard;
