/* eslint-disable */
// @ts-nocheck

/* eslint-enable prettier/prettier */
/* eslint-enable @typescript-eslint/no-unused-vars */
/* eslint-enable import/no-unresolved */

import { styled } from "@mui/system";
import Spinner from "@untyped-components/Spinner/Spinner";

export const SpinnerCompContainer = styled("div")({
  minHeight: "200px",
  position: "relative",
});

export const SpinnerCompContent = styled(Spinner)({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
});
