/* eslint-disable */
// @ts-nocheck

/* eslint-enable prettier/prettier */
/* eslint-enable @typescript-eslint/no-unused-vars */
/* eslint-enable import/no-unresolved */

import React from "react";
import PropTypes from "prop-types";

function ThankYouMessage({ withDottedLine }) {
  return (
    <>
      {withDottedLine && <div className="line line-lg line-dashed pull-in" />}
      <p> {I18n.t("surveys.new_attempt.success")} </p>
      {withDottedLine && <div className="line line-lg line-dashed pull-in" />}
    </>
  );
}

ThankYouMessage.propTypes = {
  withDottedLine: PropTypes.bool,
};

export default ThankYouMessage;
