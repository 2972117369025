/* eslint-disable */
// @ts-nocheck

/* eslint-enable prettier/prettier */
/* eslint-enable @typescript-eslint/no-unused-vars */
/* eslint-enable import/no-unresolved */

export const getValidAnswers = (answers) => {
  return answers.filter(({ input_value }) => input_value);
};
