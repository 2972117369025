/* eslint-disable */
// @ts-nocheck

/* eslint-enable prettier/prettier */
/* eslint-enable @typescript-eslint/no-unused-vars */
/* eslint-enable import/no-unresolved */

import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import SubmitButton from "@untyped-shared/materialUI/buttons/submitButton";
import { useFormikContext } from "formik";

const useStyles = makeStyles(() => ({
  bulletPoint: {
    marginTop: "1px",
    marginLeft: "5px",
    float: "left",
  },
  buttonWrapper: {
    paddingBottom: "10px",
    marginLeft: "46px",
  },
}));

function SurveySubmitButton() {
  const classes = useStyles();
  const { isSubmitting } = useFormikContext();

  return (
    <article className="comment-item last-item">
      <div className={`fa-stack ${classes.bulletPoint}`}>
        <i className="fa fa-circle text-success fa-stack-2x" />
        <i className="fa fa-check fa-stack-1x text-white" />
      </div>
      <section className={classes.buttonWrapper}>
        <SubmitButton disabled={isSubmitting} />
      </section>
    </article>
  );
}

export default SurveySubmitButton;
