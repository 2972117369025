/* eslint-disable */
// @ts-nocheck

/* eslint-enable prettier/prettier */
/* eslint-enable @typescript-eslint/no-unused-vars */
/* eslint-enable import/no-unresolved */

import {
  createAsyncThunk,
  createSlice,
  createEntityAdapter,
  createSelector,
} from "@reduxjs/toolkit";

import DataService from "@untyped-services/dataService";

const dataService = new DataService();

const getPhoneConference = createAsyncThunk(
  "phoneConference/getPhoneConference",
  async ({ phoneConferenceId }) => {
    const url = `/api/phone_conference/${phoneConferenceId}`;

    return dataService.getData(url).then((res) => res.data);
  },
);

export const moderateParticipant = createAsyncThunk(
  "phoneConference/moderateParticipant",
  async ({ url, participant_sid, action }) => {
    return dataService
      .putData(url, {
        phone_conference: {
          participant_sid,
          action,
        },
      })
      .then((res) => res.data);
  },
);

const listFilter = (participants) => {
  Object.keys(participants).forEach((key) => {
    if (participants[key].name?.includes("sip:phone-bridge")) {
      delete participants[key];
    }
  });
  return participants;
};

// implementation based on
// https://redux-toolkit.js.org/api/createEntityAdapter
const phoneConferenceAdapter = createEntityAdapter();

// selectors from entityAdapter
const phoneConferenceSelectors = phoneConferenceAdapter.getSelectors(
  (state) => state.phoneConferences,
);

export const phoneConferencesSlice = createSlice({
  name: "phoneConferences",
  initialState: phoneConferenceAdapter.getInitialState({
    participants: {},
    loading: false,
    error: "",
  }),
  reducers: {},
  extraReducers: {
    [getPhoneConference.pending]: (state) => {
      state.loading = true;
    },
    [getPhoneConference.fulfilled]: (state, action) => {
      phoneConferenceAdapter.setOne(state, action.payload);
      state.loading = false;
      state.error = "";
    },
    [moderateParticipant.pending]: (state, redux_action) => {
      Util.log("Optimistic Update with action:");
      Util.log(redux_action);
    },
  },
});

export const selectParticipants = (state) => {
  return state.phoneConference;
};

export const selectFilteredParticipants = createSelector(
  (state, id) => selectPhoneConference(state, id),
  (conference) => conference && listFilter({ ...conference.participants }),
);

export { getPhoneConference };

export const selectPhoneConference = createSelector(
  [(state) => state, (state, id) => id],
  (state, id) => phoneConferenceSelectors.selectById(state, id),
);

export default phoneConferencesSlice.reducer;
