/* eslint-disable */
// @ts-nocheck

/* eslint-enable prettier/prettier */
/* eslint-enable @typescript-eslint/no-unused-vars */
/* eslint-enable import/no-unresolved */

import { styled } from "@mui/system";
import Tippy from "@tippyjs/react";

export const VoteTooltip = styled(Tippy)({
  backgroundColor: "#eeeeee",
  color: "#594f8d",
  fontSize: "1.2rem",
  lineHeight: "1.4",
  padding: ".40em",
  textAlign: "center",
  borderRadius: "3px",
  "& .tippy-arrow": {
    color: "#eeeeee",
  },
});

export default VoteTooltip;
