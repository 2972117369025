/* eslint-disable */
// @ts-nocheck

/* eslint-enable prettier/prettier */
/* eslint-enable @typescript-eslint/no-unused-vars */
/* eslint-enable import/no-unresolved */

import React from "react";
import PropTypes from "prop-types";

import StyledTag from "./StyledTag";

function Tag({ text, color, margin }) {
  return <StyledTag sx={{ backgroundColor: color, margin }}>{text}</StyledTag>;
}

Tag.propTypes = {
  text: PropTypes.string,
  color: PropTypes.string,
  margin: PropTypes.string,
};

export default Tag;
