/* eslint-disable */
// @ts-nocheck

/* eslint-enable prettier/prettier */
/* eslint-enable @typescript-eslint/no-unused-vars */
/* eslint-enable import/no-unresolved */

/* global appConfig */

export default class EventService {
  constructor() {}

  getEventIdFromAppConfig() {
    if (
      typeof appConfig === "undefined" ||
      typeof appConfig.event === "undefined"
    )
      return false;
    return appConfig.event.encoded_id;
  }
}
