/* eslint-disable */
// @ts-nocheck

/* eslint-enable prettier/prettier */
/* eslint-enable @typescript-eslint/no-unused-vars */
/* eslint-enable import/no-unresolved */

/* global I18n */

import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector, useDispatch } from "react-redux";

import { Grid } from "@material-ui/core";
import SubmitButton from "@untyped-shared/materialUI/buttons/submitButton";

import QuestionService from "../services/questionService";
import { Formik, Form, Field } from "formik";

import {
  selectAnonymousQuestions,
  selectMandatoryName,
} from "@untyped-store/selectors/activeEvent";

import { createQuestion } from "@untyped-store/slices/questionsSlice";

import { selectSelectedRoom } from "@untyped-store/slices/questionRoomsSlice";

const useStyles = makeStyles((theme) => ({
  boxTextAuthor: {
    fontSize: "12px",
    resize: "vertical",
    maxHeight: "344px",
  },
  boxText: {
    fontSize: "12px",
    padding: "5px 10px",
    resize: "vertical",
    maxHeight: "344px",
  },
  labelContainer: {
    minWidth: 120,
  },
  spanFont: {
    fontSize: "1.2rem !important",
    color: "#afafaf",
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.1rem !important",
    },
  },
  formContainer: {
    [theme.breakpoints.down(600)]: {
      display: "block",
    },
  },
  questionBox: {
    [theme.breakpoints.down(600)]: {
      display: "block",
    },
  },
  errorMessage: {
    color: "#a94442",
  },
}));

function FormQuestionHorizontal() {
  const classes = useStyles();
  const service = new QuestionService();
  const config = service.getConfigForm();
  const anonymousQuestions = useSelector(selectAnonymousQuestions);
  const mandatoryName = useSelector(selectMandatoryName);
  const dispatch = useDispatch();
  const maxLength = config.maxLengthQuestion * 1;
  const selectedQuestionRoom = useSelector(selectSelectedRoom);

  const sendNewQuestion = (values, { setSubmitting }) => {
    if (anonymousQuestions || !values.authorName) {
      values.authorName = I18n.t("anonymous");
    }

    const newQuestion = {
      attendee_id: config.attendeeId,
      message: values.textQuestion,
      publishing_name: values.authorName,
      chat_room_id: selectedQuestionRoom.id,
    };

    dispatch(createQuestion({ question: newQuestion }));

    setTimeout(() => {
      Util.alert(config.alertSuccessMessage, "success", "success", 2000);
      values.textQuestion = "";
      setSubmitting(false);
    }, 400);
  };

  // Refactor this function to use Yup
  const validateInput = (values) => {
    const errors = {};
    if (!anonymousQuestions && mandatoryName && !values.authorName) {
      errors.authorName = true;
    }
    if (!values.textQuestion) {
      errors.textQuestion = true;
    }
    if (values.textQuestion.length > maxLength) {
      errors.textLength = true;
    }
    return errors;
  };

  return (
    <div id="ask_your_q">
      <Formik
        initialValues={{
          textQuestion: "",
          authorName: config.defaultName,
        }}
        validate={validateInput}
        onSubmit={sendNewQuestion}
      >
        {({ errors, isSubmitting, values, isValid, dirty }) => (
          <Form>
            <Grid
              container
              spacing={2}
              alignItems="flex-end"
              className={classes.formContainer}
            >
              {!anonymousQuestions && (
                <Grid item sm={4}>
                  <label htmlFor="authorName">{config.authorLabel}</label>
                  <Field
                    className={`form-control ${classes.boxTextAuthor}`}
                    name="authorName"
                    placeholder={I18n.t("anonymous")}
                  />
                </Grid>
              )}
              <Grid item container sm={6} className={classes.questionBox}>
                <Grid item container>
                  <Grid item container justifyContent="space-between">
                    <Grid item className={classes.labelContainer}>
                      <label htmlFor="textQuestion"> {config.textLabel} </label>
                    </Grid>
                    <Grid item>
                      <span
                        className={
                          `${classes.spanFont} ` +
                          `${errors.textLength ? classes.errorMessage : ""}`
                        }
                      >
                        {I18n.t("questions.message_hint", {
                          num: `${maxLength - values.textQuestion.length}`,
                        })}
                      </span>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item sm={12}>
                  <Field
                    className={`form-control ${classes.boxText}`}
                    name="textQuestion"
                    placeholder={config.textPlaceholder}
                  />
                </Grid>
              </Grid>
              <Grid item xs={2}>
                <SubmitButton
                  disabled={
                    !selectedQuestionRoom || isSubmitting || !isValid || !dirty
                  }
                  fullWidth
                />
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default FormQuestionHorizontal;
