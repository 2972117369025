/* eslint-disable */
// @ts-nocheck

/* eslint-enable prettier/prettier */
/* eslint-enable @typescript-eslint/no-unused-vars */
/* eslint-enable import/no-unresolved */

import { createSlice } from "@reduxjs/toolkit";

export const jwtSlice = createSlice({
  name: "jwt",
  initialState: { token: "" },
  reducers: {
    setJWT(state, action) {
      Util.log(action.payload);
      state.token = action.payload;
    },
  },
});

// actions
export const { setJWT } = jwtSlice.actions;

export default jwtSlice.reducer;
